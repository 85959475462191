import AOS from '../../node_modules/aos/dist/aos.js';

jQuery(document).ready(function($) {
    AOS.init({
        offset: 200,
        duration: 1000,
        easing: 'ease-in-sine',
        delay: 100,
    });
});


