/* ==========================================================================
    Standard Multi-level and Mobile Navigation
========================================================================== */

jQuery(document).ready(function($){

    if ($(window).width() < 1024) {
        $('#resident-portal-item').appendTo('.nav__main-nav--list');
    }
    // Mobile menu toggle
    $('.menu-toggle').on('click',function(e){
        $('.nav__main-nav').slideToggle('fast');
        $(this).toggleClass('close');
        e.preventDefault();
    });


    $('#menu-main-navigation li').click(function () {
        $('#menu-main-navigation li').removeClass('menu-item-active');
        $(this).toggleClass('menu-item-active');
    });
});
