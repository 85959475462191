import { tns } from "/node_modules/tiny-slider/src/tiny-slider"

jQuery(document).ready(function($) {
    if ($('.header__slider').length > 0) {
        let headerSlider = tns({
            container: '.header__slider',
            items: 1,
            slideBy: 'page',
            autoplay: true,
            controls: false,
            navPosition: 'bottom',
            autoplayButton: false,
            autoplayButtonOutput: false,
            speed: 1000,
            autoplayTimeout: 8000,
        });
    }
})
