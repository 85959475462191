//Sass
import './sass/main.scss';

//JS
import './js/stark_custom.js';
import './js/navigation.js';
import './js/sliders.js';
import './js/carousel.js';
import './js/sightmap-floorplans-modal.js';
import './js/tabs.js';
import './js/aos.js';
import './js/mobile.js';
import './js/maps.js';