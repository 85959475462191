jQuery(document).ready(function($){
    $('#tabs li a:not(:first)').addClass('inactive');
    $('.tab_container').hide();
    $('.tab_container:first').show();
        
    $('#tabs li a').on('click', function(){
        var t = $(this).attr('id');
        if($(this).hasClass('inactive')){ 
            $('#tabs li a').addClass('inactive');           
            $(this).removeClass('inactive');
            $('.tab_container').hide();
            $('#'+ t + 'C').fadeIn('slow');
        }
    });
});

