import '/node_modules/slick-carousel/slick/slick';
import '/node_modules/slick-carousel/slick/ajax-loader.gif';

jQuery(document).ready(function($) {
    if ($('.carousel').length > 0) {
        $('.carousel .slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.slider-nav',
            adaptiveHeight: true
        });

        $('.carousel .slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: true,
            arrows: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        dots: false
                    }
                }
            ]
        });
    }

    // Amenities on home page
    $('.split-content__slider').slick({
        infinite: false,
        dots: true,
        slidesToShow: 1,
        arrows: false,
        fade: true,
        // appendDots: '.images-div',
        initialSlide: 0
    });    

    // css fix
    // $('#slick-slide00 .slick-dots li:first-child').addClass('slick-active');
    // $('.slick-dots li').click(function() {
    //   var dotIndex = $(this).index() + 1;
    //   $('ul li.slick-active').removeClass('slick-active');
    //   $('ul.slick-dots li:nth-child(' + dotIndex + ')').addClass('slick-active');
    // });

    $('.noUi-tooltip').text('$' + $('.noUi-tooltip').text());
});



