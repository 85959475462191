document.addEventListener('DOMContentLoaded', function() {
  const floorPlanLinks = document.querySelectorAll('.floor-plan-link');
  const modalContainers = document.querySelectorAll('.modal-container');

  floorPlanLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      const floorPlanId = link.getAttribute('data-floor-plan-id');
      const modalContainer = document.querySelector(`.modal-container[data-floor-plan-id="${floorPlanId}"]`);
      modalContainer.style.display = 'block';
    });
  });

  modalContainers.forEach((modal) => {
    modal.addEventListener('click', (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    });
  });
});