jQuery(document).ready(function($) {
    $('.rp-archive-fp-sidebar').appendTo('.rp-archive-fp-nav');
    $('.rp-apply-filters, .rp-filter-reset').appendTo('.rp-archive-fp-sidebar');
    $('.rp-filter-reset').appendTo('.rp-apply-filters');
    $('.rp-archive-fp-mobile-filter-header').click(function() {
        $('.rp-archive-fp-sidebar').slideToggle();
    })
    $('.rp-archive-fp-mobile-filter-title.rp-button').html('<i class="rp-icon-equalizer"></i>Filter');
    $('#rp-archive-fp-bed-filter input').each(function () {
        $(this).appendTo($(this).parent().parent());
        $(this).parent().find('label').appendTo($(this).parent());
        $(this).parent().find('label').html(parseInt($(this).parent().find('label').html(), 10));
    })
    // duplicated due to reload of ajax when apply/reset button clicked
    $('#apply_filters_button, #reset_button').click(function() {
        $('#rp-archive-fp-bed-filter input').each(function () {
            $(this).appendTo($(this).parent().parent());
            $(this).parent().find('label').appendTo($(this).parent());
            $(this).parent().find('label').html(parseInt($(this).parent().find('label').html(), 10));
        })
    })


    $('.featured-floorplan--0').hover(function() {
            $('.featured-floorplan--image-1').hide();
            $('.featured-floorplan--image-0').show();
        }
    )
    $('.featured-floorplan--1').hover(function() {
        $('.featured-floorplan--image-0').hide();
        $('.featured-floorplan--image-1').show();
        }
    )

    // restyle floorplan buttons
    $('.rp-apply-filters, .rp-filter-reset').prepend('<hr>');
    $('.floorplans .unit-modal.unit-modal-is-active .form-buttons .rp-button, .floorplans .unit-modal.unit-modal-is-active .form-buttons .rp-button-alt').before('<hr>');

    // add wording to floorplan modal
    $('.unit-modal-grid-container h2 ~ div').append('<div>Contact the leasing office for the most recent pricing information.</div>');
    $('.rp-unit-card .unit-selector h5 + p').hide();

})