jQuery(document).ready(function($){

  // are we on mobile?
  const ua = navigator.userAgent;
  const testing = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);

  // handle chatbox
  if (testing) {
    var lastScrollTop = 0, delta = 5;
    $(window).scroll(function(event){
      var st = $(this).scrollTop();
      
      if(Math.abs(lastScrollTop - st) <= delta)
          return;
    
      if (st > lastScrollTop){
          // downscroll code
          $('.chatbox').hide();
          $('.sx_speech-bubble').hide();
      } else {
          // upscroll code
          $('.chatbox').show();
          $('.sx_speech-bubble').show();
      }
      lastScrollTop = st;
    });
  }
});